import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig'; // 确保你导入的是 axiosInstance
import './TrialResidenceForm.css';
import { format } from 'date-fns'; // 使用 date-fns 进行日期格式化
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate 钩子

const TrialResidenceForm = ({ keycloak }) => {
  const [visitors, setVisitors] = useState([]); // 访客列表
  const [planners, setPlanners] = useState([]); // 规划师列表
  const [selectedVisitorId, setSelectedVisitorId] = useState(''); // 选择的访客ID
  const [visitorName, setVisitorName] = useState(''); // 访客姓名
  // 获取当前日期，并设置试入住开始日期为今天
  const today = format(new Date(), 'yyyy-MM-dd');
  // 设置试入住结束日期为三天后
  const threeDaysLater = format(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
  const [trialStartDate, setTrialStartDate] = useState(today); // 试入住开始日期
  const [trialEndDate, setTrialEndDate] = useState(threeDaysLater); // 试入住结束日期
  const [filterDate, setFilterDate] = useState(''); // 筛选日期
  const [filterPlanner, setFilterPlanner] = useState(''); // 筛选规划师
  const [secretary, setSecretary] = useState(''); // 秘书字段
  const [roomNumber, setRoomNumber] = useState(''); // 房号
  const [status, setStatus] = useState('待入住'); // 入住状态，默认"待入住"
  const [birthDate, setBirthDate] = useState('1950-01-01'); // 出生日期
  const [birthPlace, setBirthPlace] = useState(''); // 户籍地（可为空）
  const [secretaryFollowUp, setSecretaryFollowUp] = useState(''); // 秘书跟进记录（可为空）

  const navigate = useNavigate(); // 用于跳转页面

  // 获取去重的规划师列表
  useEffect(() => {
    const fetchPlanners = async () => {
      try {
        const response = await axiosInstance.get('/visitors/planners'); // 获取去重规划师列表
        setPlanners(response.data);
      } catch (error) {
        console.error('Error fetching planners:', error);
      }
    };

    fetchPlanners();
  }, []);

  // 根据筛选条件（日期和规划师）获取访客列表
  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        let formattedDate = filterDate ? format(new Date(filterDate), 'yyyy-MM-dd') : undefined;

        const response = await axiosInstance.get('/visitors', {
          params: {
            visit_time: formattedDate || undefined,
            planner: filterPlanner || undefined,
          },
        });

        setVisitors(response.data); // 更新访客列表
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    fetchVisitors();
  }, [filterDate, filterPlanner]); // 每次日期或规划师变动时，更新访客列表

  // 获取当前登录用户的姓名，使用keycloak.tokenParsed
  useEffect(() => {
    if (keycloak && keycloak.tokenParsed) {
      const userFullName = keycloak.tokenParsed.family_name + keycloak.tokenParsed.given_name;
      setSecretary(userFullName); // 设置默认的秘书姓名
    }
  }, [keycloak]); // 依赖keycloak来确保其正确加载后设置默认值

  // 查重函数，检查访客是否已经存在
  const checkDuplicate = async (visitorId) => {
    try {
      const response = await axiosInstance.post('/trial-residence/check-duplicate', { visitorId });
      return response.data.exists; // 如果返回值为 true，说明访客记录已存在
    } catch (error) {
      console.error('Error checking duplicate:', error);
      return false; // 如果查重失败，默认允许继续提交
    }
  };

  // 当选择访客时，自动填充姓名字段，并允许用户编辑
  const handleVisitorChange = (e) => {
    const visitorId = e.target.value;
    setSelectedVisitorId(visitorId);

    const selectedVisitor = visitors.find((visitor) => visitor.id === parseInt(visitorId));
    if (selectedVisitor) {
      setVisitorName(selectedVisitor.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Step 1: 检查是否有重复的访客记录
      const isDuplicate = await checkDuplicate(selectedVisitorId);
      if (isDuplicate) {
        alert('该访客的试入住记录已经存在，不能重复提交。');
        return; // 停止提交
      }

      // Step 2: 如果没有重复，继续提交数据
      const postData = {
        visitorId: selectedVisitorId,
        visitorName, // 访客姓名
        trialStartDate,
        trialEndDate,
        secretary,
        roomNumber, // 房号
        status, // 入住状态
        secretaryFollowUp: secretaryFollowUp || null, // 秘书跟进记录，允许为空
        birthDate: birthDate || null, // 出生日期
        birthPlace: birthPlace || null // 户籍地，允许为空
      };

      console.log("Sending data to backend:", postData);

      await axiosInstance.post('/trial-residence/add', postData);

      alert('试入住记录已成功添加');
      navigate('/trial-residences'); // 提交成功后跳转到查询页面
    } catch (error) {
      console.error('Error adding trial residence:', error);
      alert('提交失败，请稍后再试。');
    }
  };

  return (
    <div className="trial-residence-form">
      <h2>添加试入住记录</h2>
      <form onSubmit={handleSubmit}>
        <div className="filter-container">
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)} // 日期筛选变动时触发
          />
          <select
            value={filterPlanner}
            onChange={(e) => setFilterPlanner(e.target.value)} // 规划师筛选变动时触发
          >
            <option value="">所有规划师</option>
            {planners.map((planner, index) => (
              <option key={index} value={planner}>
                {planner}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>选择访客:</label>
          <select
            value={selectedVisitorId || ''}
            onChange={handleVisitorChange} // 当访客选择改变时，自动获取访客ID和姓名
            required
          >
            <option value="">请选择访客</option>
            {visitors.map((visitor) => (
              <option key={visitor.id} value={visitor.id}>
                {visitor.name} - {format(new Date(visitor.visitTime), 'yyyy-MM-dd')} - {visitor.planner}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>访客姓名:</label>
          <input
            type="text"
            value={visitorName}
            onChange={(e) => setVisitorName(e.target.value)} // 姓名可以被编辑
            required
          />
        </div>
        <div className="form-group">
          <label>试入住开始日期:</label>
          <input
            type="date"
            value={trialStartDate}
            onChange={(e) => setTrialStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>试入住结束日期:</label>
          <input
            type="date"
            value={trialEndDate}
            onChange={(e) => setTrialEndDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>房号:</label>
          <input
            type="text"
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>入住状态:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="待入住">待入住</option>
            <option value="已入住">已入住</option>
            <option value="已退房">已退房</option>
          </select>
        </div>
        <div className="form-group">
          <label>秘书:</label>
          <input
            type="text"
            value={secretary}
            onChange={(e) => setSecretary(e.target.value)} // 如果用户需要，可以修改秘书姓名
            required
          />
        </div>
        <div className="form-group">
          <label>出生日期:</label>
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>户籍地:</label>
          <input
            type="text"
            value={birthPlace}
            onChange={(e) => setBirthPlace(e.target.value)} // 绑定户籍地
          />
        </div>
        <div className="form-group">
          <label>秘书跟进记录:</label>
          <textarea
            value={secretaryFollowUp}
            onChange={(e) => setSecretaryFollowUp(e.target.value)} // 绑定秘书跟进记录
          />
        </div>
        <button type="submit">保存试入住记录</button>
      </form>
    </div>
  );
};

export default TrialResidenceForm;
